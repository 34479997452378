import { Textarea } from '@navozyme/uikit/dist/atoms/Textarea';
import '../styles/summary.scss';
import SummaryDocument from './SummaryDocument';

export const PortInstructionItem = ({ title, subtitle, documents, instructionsText, emptyDocumentsMessage }) => {
  const getDocumentUuid = (document) => document?.documentUid || document?.templateUid;

  return (
    <div className='summary-documents-block'>
      {title && <h3 className={`document-type-title document-type-title_with-title-button`}>{title}</h3>}
      {subtitle && <p className='page-subtitle'>{subtitle}</p>}
      <Textarea
        name='instructionsText'
        id='instructionsText'
        sizeInput='large'
        defaultValue={instructionsText || ''}
        rows='10'
        disabled={true}
      />
      <div
        style={{
          padding: '10px',
        }}></div>
      {documents && documents.length > 0 ? (
        <div className='documents-block'>
          {documents.map((document) => {
            return <SummaryDocument key={getDocumentUuid(document)} document={document} instructionDoc={true} />;
          })}
        </div>
      ) : (
        <p>{emptyDocumentsMessage}</p>
      )}
    </div>
  );
};
