import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import auth from '../../../../../config/auth';
import { axiosDocumentsInstance, axiosInstance } from '../../../../../config/axios';
import { DOCUMENT_TYPE_IDS, DOCUMENT_TYPE_NAMES } from '../constants';

const initialState = {
  uploadedDocuments: {},

  vesselCertificates: [],

  documents: {},

  isLoading: {},

  types: {},

  portInstructions: [],

  isInstructionOpened: false,
};

export const getDocumentTypes = createAsyncThunk('documents/types', async () => {
  const headers = auth.getHeaders();
  let response = await axiosInstance.get(`/document-types`, {
    headers,
  });
  return response.data;
});
export const selectDocumentsTypes = (state) => state.documents.types;

export const getPortInstructions = createAsyncThunk('portInstructions', async ({ portUnloCode, agentCompanyUid }) => {
  const headers = auth.getHeaders();
  let response = await axiosInstance.get(
    `/portcall-instructions?unlocode=${portUnloCode}&companyUid=${agentCompanyUid}`,
    {
      headers,
    }
  );
  return response.data;
});

export const generatePreArrivalDocuments = createAsyncThunk('pre-arrival/generate', async ({ uid }) => {
  const headers = auth.getHeaders();
  let response = await axiosInstance.put(
    `portcalls/${uid}/pre-arrival-documents`,
    {},
    {
      headers,
    }
  );

  return { status: response.status, message: response.statusText };
});

export const generatePreDepartureDocuments = createAsyncThunk('pre-departure/generate', async ({ uid }) => {
  const headers = auth.getHeaders();
  let response = await axiosInstance.put(
    `portcalls/${uid}/pre-departure-documents`,
    {},
    {
      headers,
    }
  );

  return { status: response.status, message: response.statusText };
});

export const getRequiredDocuments = createAsyncThunk('documents/getRequiredDocuments', async ({ uid }) => {
  const headers = auth.getHeaders();

  let response = await axiosInstance.get(`portcalls/${uid}/documents`, {
    headers,
  });

  return {
    documents: response.data.data,
  };
});

export const getVesselCertificates = createAsyncThunk('documents/getVesselCertificates', async ({ vesselUid }) => {
  const headers = auth.getHeaders();

  let response = await axiosInstance.get(`/vessels/${vesselUid}/ship-certificates`, {
    headers,
  });

  return {
    documents: response.data.data,
  };
});

export const uploadLocalFormalities = createAsyncThunk(
  'documents/uploadLocalFormalities',
  async (
    { file, documentUid, documentTypeId, category, portcallId, documentSubCategory, documentTypeName, templateUid },
    { getState }
  ) => {
    const headers = auth.getHeaders();
    const { documents } = getState();

    const formData = new FormData();
    formData.append('file', file);

    let url = `document/${documentUid}`;
    await axiosDocumentsInstance.put(url, formData, {
      headers: {
        ...headers,
        'Content-Type': 'multipart/form-data',
      },
    });

    let newDocuments = documents.documents[category] || [];
    newDocuments = documents.documents[category].map((document) => {
      if (document.documentType.id === documentTypeId && document.templateUid === templateUid) {
        const data = {
          ...document,
          documentUid: documentUid,
          lastUpdated: `${new Date().toISOString()}`,
          documentType: {
            ...document.documentType,
            name: documentTypeName,
          },
        };
        return data;
      } else {
        return document;
      }
    });
    if (newDocuments) {
      await axiosInstance.put(
        `portcalls/${portcallId}/documents`,
        {
          [category]: newDocuments,
        },
        {
          headers,
        }
      );
    }

    return {
      [category]: newDocuments,
    };
  }
);

export const uploadDocument = createAsyncThunk(
  'documents/uploadDocument',
  async (
    {
      file,
      documentUid,
      documentTypeId,
      category,
      portcallId,
      expiryDate,
      issueDate,
      documentCategory,
      documentSubCategory,
      documentTypeName,
      isShipCertificate = false,
    },
    { getState }
  ) => {
    const headers = auth.getHeaders();
    const { documents } = getState();
    const isPDF = file.type === 'application/pdf';

    if (['preArrivalDocuments', 'preDepartureDocuments'].includes(category) && !isPDF) {
      documentTypeName = DOCUMENT_TYPE_NAMES.CREW_EXCEL_DOCUMENT;
    }

    const formData = new FormData();
    formData.append('file', file);

    let url = `document/${documentUid}`;
    if (isShipCertificate) {
      url = `document/${documentUid}?secure=true`;
    }
    await axiosDocumentsInstance.put(url, formData, {
      headers: {
        ...headers,
        'Content-Type': 'multipart/form-data',
      },
    });

    let newDocuments = documents.documents[category] || [];
    // Check if a document of the same file type category already exists
    const existingDocument = documents.documents[category]?.find((document) => {
      const existingIsPDF = !document.documentType.name.includes(DOCUMENT_TYPE_NAMES.CREW_EXCEL_DOCUMENT);
      return document.documentType.id === documentTypeId && ((isPDF && existingIsPDF) || (!isPDF && !existingIsPDF));
    });

    if (existingDocument) {
      // Update the existing document if the file types match
      newDocuments = documents.documents[category].map((document) => {
        if (
          document.documentType.id === documentTypeId &&
          ((isPDF && !document.documentType.name.includes(DOCUMENT_TYPE_NAMES.CREW_EXCEL_DOCUMENT)) ||
            (!isPDF && document.documentType.name.includes(DOCUMENT_TYPE_NAMES.CREW_EXCEL_DOCUMENT)))
        ) {
          const data = {
            ...document,
            documentUid: documentUid,
            lastUpdated: `${new Date().toISOString()}`,
            documentType: {
              ...document.documentType,
              name: documentTypeName,
            },
          };
          if (category === 'requestedCertificates') {
            data.issueDate = issueDate;
            data.expiryDate = expiryDate;
          }
          return data;
        } else {
          return document;
        }
      });
    } else {
      // Add the new document if no existing document of the same type is found
      newDocuments = [
        ...newDocuments,
        {
          lastUpdated: `${new Date().toISOString()}`,
          documentUid: documentUid,
          ...(category === 'requestedCertificates' && {
            issueDate: issueDate,
            expiryDate: expiryDate,
          }),
          status: 'Draft',
          documentType: {
            id: documentTypeId,
            name: documentTypeName,
            category: documentCategory,
            subcategory: documentSubCategory,
          },
        },
      ];
    }

    if (newDocuments) {
      await axiosInstance.put(
        `portcalls/${portcallId}/documents`,
        {
          [category]: newDocuments,
        },
        {
          headers,
        }
      );
    }

    return {
      [category]: newDocuments,
    };
  }
);

export const requestDocuments = createAsyncThunk('documents/requestDocuments', async ({ uid, category, data }) => {
  const headers = auth.getHeaders();

  await axiosInstance.put(
    `portcalls/${uid}/documents`,
    {
      [category]: data,
    },
    {
      headers,
    }
  );
  return { category: category, data: data };
});

export const getDocument = createAsyncThunk('documents/getDocument', async ({ uiid, documentTypeId }) => {
  const headers = auth.getHeaders();

  let response = await axiosDocumentsInstance.get(`document/${uiid}`, {
    headers,
  });

  return response.data;
});

export const downloadDocument = createAsyncThunk('documents/downloadDocument', async ({ uiid, fileName }) => {
  const headers = auth.getHeaders();

  let response = await axiosDocumentsInstance.get(`document/download/${uiid}`, {
    headers,
    responseType: 'arraybuffer',
  });

  const uint8Array = new Uint8Array(response.data);

  const file = new Blob([uint8Array.buffer], { type: 'application/pdf' });
  const url = URL.createObjectURL(file);

  // Create a temporary link element
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  link.click();

  // Clean up the temporary URL and link
  URL.revokeObjectURL(url);
  link.remove();
});

export const openDocument = createAsyncThunk('documents/openDocument', async ({ uiid, portcallId }) => {
  const headers = auth.getHeaders();
  let response = await axiosDocumentsInstance.get(`document/download/${uiid}`, {
    headers,
    responseType: 'arraybuffer',
  });

  const contentType = response.headers['content-type'];
  const uint8Array = new Uint8Array(response.data);
  const file = new Blob([uint8Array.buffer], { type: contentType });
  const url = URL.createObjectURL(file);

  if (contentType === 'application/pdf') {
    // If it's a PDF, open it in a new tab
    window.open(url, '_blank');
  } else {
    // If it's not a PDF, trigger a download
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${portcallId}.${getExtension(contentType)}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
});

// Helper function to get the file extension based on content type
function getExtension(contentType) {
  switch (contentType) {
    case 'text/csv':
      return 'csv';
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'xlsx';
    case 'application/vnd.ms-excel':
      return 'xls';
    default:
      return 'bin';
  }
}

export const removeDocument = createAsyncThunk(
  'documents/removeDocument',
  async ({ file, documentUid, documentTypeId, category, portcallId }, { getState }) => {
    const headers = auth.getHeaders();
    const { documents } = getState();

    // Filter documents based on the presence of documentUid
    const newDocuments = documents.documents[category].filter((document) => {
      if (documentUid) {
        return document.documentUid !== documentUid;
      }
      return document.documentType.id !== documentTypeId;
    });

    // Update documents on the server
    await axiosInstance.put(
      `portcalls/${portcallId}/documents`,
      {
        [category]: newDocuments,
      },
      {
        headers,
      }
    );

    return {
      [category]: newDocuments,
    };
  }
);

export const removeUploadedDocument = createAsyncThunk(
  'documents/removeDocument',
  async ({ file, documentUid, documentTypeId, category, portcallId, templateUid }, { getState }) => {
    const headers = auth.getHeaders();
    const { documents } = getState();

    const newDocuments = documents.documents[category].map((document) => {
      if (document.documentUid === documentUid) {
        const updatedDocument = { ...document };
        delete updatedDocument.documentUid;
        if (updatedDocument.issueDate) {
          delete updatedDocument.issueDate;
        }
        if (updatedDocument.expiryDate) {
          delete updatedDocument.expiryDate;
        }
        if (updatedDocument.updatedAt) {
          delete updatedDocument.updatedAt;
        }
        return updatedDocument;
      }
      return document;
    });

    await axiosInstance.put(
      `portcalls/${portcallId}/documents`,
      {
        [category]: newDocuments,
      },
      {
        headers,
      }
    );

    return {
      [category]: newDocuments,
    };
  }
);

export const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    setOpenInstruction(state, action) {
      state.isInstructionOpened = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRequiredDocuments.pending, (state, { meta }) => {})
      .addCase(getRequiredDocuments.fulfilled, (state, action) => {
        const { documents } = action.payload;
        state.documents = documents;
      })
      .addCase(getRequiredDocuments.rejected, (state, { meta }) => {})
      .addCase(getVesselCertificates.pending, (state, { meta }) => {})
      .addCase(getVesselCertificates.fulfilled, (state, action) => {
        const { documents } = action.payload;
        state.vesselCertificates = documents;
      })
      .addCase(getVesselCertificates.rejected, (state, { meta }) => {})
      .addCase(requestDocuments.pending, (state, { meta }) => {})
      .addCase(requestDocuments.fulfilled, (state, action) => {
        if (action.payload) {
          state.documents = {
            ...state.documents,
            [action.payload.category]: action.payload.data,
          };
        }
      })
      .addCase(requestDocuments.rejected, (state, { meta }) => {})
      .addCase(
        getDocument.pending,
        (
          state,
          {
            meta: {
              arg: { documentTypeId },
            },
          }
        ) => {
          state.isLoading[documentTypeId] = true;
        }
      )
      .addCase(
        getDocument.fulfilled,
        (
          state,
          {
            payload,
            meta: {
              arg: { documentTypeId },
            },
          }
        ) => {
          const { documentUid } = payload.data.attributes;

          state.uploadedDocuments[documentUid] = {
            document: payload.data.attributes,
          };

          state.isLoading[documentTypeId] = false;
        }
      )
      .addCase(
        getDocument.rejected,
        (
          state,
          {
            meta: {
              arg: { uiid },
            },
          }
        ) => {
          state.uploadedDocuments[uiid].isLoading = false;
        }
      )
      .addCase(
        uploadDocument.pending,
        (
          state,
          {
            meta: {
              arg: { documentTypeId },
            },
          }
        ) => {
          state.isLoading[documentTypeId] = true;
        }
      )
      .addCase(
        uploadDocument.fulfilled,
        (
          state,
          {
            payload,
            meta: {
              arg: { documentTypeId },
            },
          }
        ) => {
          state.documents = {
            ...state.documents,
            ...payload,
          };

          state.isLoading[documentTypeId] = false;
        }
      )
      .addCase(uploadDocument.rejected, (state, { meta }) => {})
      .addCase(
        uploadLocalFormalities.pending,
        (
          state,
          {
            meta: {
              arg: { templateUid },
            },
          }
        ) => {
          state.isLoading[templateUid] = true;
        }
      )
      .addCase(
        uploadLocalFormalities.fulfilled,
        (
          state,
          {
            payload,
            meta: {
              arg: { templateUid },
            },
          }
        ) => {
          state.documents = {
            ...state.documents,
            ...payload,
          };

          state.isLoading[templateUid] = false;
        }
      )
      .addCase(uploadLocalFormalities.rejected, (state, { meta }) => {})

      .addCase(
        removeDocument.pending,
        (
          state,
          {
            meta: {
              arg: { documentTypeId, templateUid },
            },
          }
        ) => {
          if (documentTypeId === DOCUMENT_TYPE_IDS.LOCAL_FORMALITY_OTHER) state.isLoading[templateUid] = true;
          else state.isLoading[documentTypeId] = true;
        }
      )
      .addCase(
        removeDocument.fulfilled,
        (
          state,
          {
            payload,
            meta: {
              arg: { documentTypeId, templateUid },
            },
          }
        ) => {
          state.documents = {
            ...state.documents,
            ...payload,
          };

          if (documentTypeId === DOCUMENT_TYPE_IDS.LOCAL_FORMALITY_OTHER) state.isLoading[templateUid] = false;
          else state.isLoading[documentTypeId] = false;
        }
      )
      .addCase(removeDocument.rejected, (state, { meta }) => {})

      .addCase(getDocumentTypes.pending, (state, { meta }) => {})
      .addCase(getDocumentTypes.fulfilled, (state, { payload }) => {
        state.types = payload.data;
      })
      .addCase(getDocumentTypes.rejected, (state, { meta }) => {})

      .addCase(getPortInstructions.pending, (state, action) => {})
      .addCase(getPortInstructions.fulfilled, (state, action) => {
        if (action.payload) {
          state.portInstructions = action.payload.data;
        }
      })
      .addCase(getPortInstructions.rejected, (state, action) => {});
  },
});

export const { setOpenInstruction } = documentsSlice.actions;

export const {} = documentsSlice.actions;

export const selectIsLoading = (state, documentTypeId) => {
  return state.documents.isLoading[documentTypeId];
};

export const selectDocuments = (state) => state.documents;

export const selectVesselCertificates = (state) => state.documents.vesselCertificates;

export const selectUploadedDocument = (state, uiid) => {
  if (state.documents.uploadedDocuments[uiid]) {
    return state.documents.uploadedDocuments[uiid];
  }
  return {};
};
export const selectSafetyDocumentsByTitle = (state, uiid) => {
  const safetyDocumentsByTitle = {};
  const { localFormalities, requestedCertificates, cargoDocuments } = state.documents.documents;

  if (localFormalities) {
    localFormalities.forEach((document) => {
      if (safetyDocumentsByTitle[document.documentType.subcategory]) {
        safetyDocumentsByTitle[document.documentType.subcategory].push(document);
      } else {
        safetyDocumentsByTitle[document.documentType.subcategory] = [document];
      }
    });
  }

  return safetyDocumentsByTitle;
};

export const selectCargoDocumentsByTitle = (state, uiid) => {
  const cargoDocumentsByTitle = {};
  const { cargoDocuments } = state.documents.documents;

  if (cargoDocuments) {
    cargoDocuments.forEach((document) => {
      if (cargoDocumentsByTitle[document.documentType.subcategory]) {
        cargoDocumentsByTitle[document.documentType.subcategory].push(document);
      } else {
        cargoDocumentsByTitle[document.documentType.subcategory] = [document];
      }
    });
  }

  return cargoDocumentsByTitle;
};

export const selectShipCertificatesByTitle = (state, uiid) => {
  const shipCertificatesByTitle = {};
  const { requestedCertificates } = state.documents.documents;

  if (requestedCertificates) {
    requestedCertificates.forEach((document) => {
      if (shipCertificatesByTitle[document.documentType.subcategory]) {
        shipCertificatesByTitle[document.documentType.subcategory].push(document);
      } else {
        shipCertificatesByTitle[document.documentType.subcategory] = [document];
      }
    });
  }

  return shipCertificatesByTitle;
};

export default documentsSlice.reducer;
