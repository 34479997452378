import React from 'react';
import { useDispatch } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { DOCUMENT_TYPE_IDS } from '../../../../captains-form/components/documents/constants';
import Button from '../../../../layout/components/Button';
import { openDocument, setOpenInstruction } from '../../documents/slices/documentsSlice';
import DocumentIcon from '../styles/images/document-icon.svg';
import EyeIcon from '../styles/images/eye-icon.svg';
import '../styles/summary.scss';
import SummaryDocumentTag from './SummaryDocumentTag';

const SummaryDocument = (props) => {
  const dispatch = useDispatch();
  const document = props.document;
  const uuid = document?.documentUid || document?.templateUid;
  const instructionDoc = props.instructionDoc;

  const onOpenDocument = () => {
    dispatch(openDocument({ uiid: uuid }));
    if (instructionDoc) {
      dispatch(setOpenInstruction(true));
    }
  };

  const formattedDate = () => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return defaultformattedDate(document?.lastUpdated, options);
  };

  const tagformattedDate = (dateStr) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return defaultformattedDate(dateStr, options);
  };

  const defaultformattedDate = (dateStr, options) => {
    if (!dateStr) return '';
    return new Date(dateStr).toLocaleDateString('en-GB', options);
  };

  const renderLastUpdated = () => {
    return ((date = formattedDate()) => (date !== '' ? `Last updated: ${date}` : ''))();
  };

  const showTags = () => document.issueDate || document.expiryDate;

  const getDocumentName = () => {
    return document?.documentType?.id === DOCUMENT_TYPE_IDS.LOCAL_FORMALITY_OTHER
      ? document?.documentType?.otherName
      : document?.name || '-';
  };

  return (
    <div className='document-box document-box__fade-in'>
      <div className='document-box-content'>
        <ReactSVG className='document-icon' src={DocumentIcon} />
        <div className='document-main-info'>
          <label>{getDocumentName()}</label>
          <label>{renderLastUpdated()}</label>
          {showTags() && (
            <div className='document-main-info-tags'>
              {document.issueDate && (
                <SummaryDocumentTag title={'Issue:'} value={tagformattedDate(document.issueDate)} />
              )}
              {document.expiryDate && (
                <SummaryDocumentTag title={'Expiry:'} value={tagformattedDate(document.expiryDate)} />
              )}
            </div>
          )}
        </div>
        <Button onClick={() => onOpenDocument()} className='light' iconLeft={EyeIcon}>
          Open
        </Button>
      </div>
    </div>
  );
};

export default SummaryDocument;
