import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import SuccessCheckmarkWhite from '../styles/images/successCheckmarkWhite.svg';
import UpdateIcon from '../styles/images/update-icon.svg';
import '../styles/summary.scss';
import SummaryDocument from './SummaryDocument';

const SummaryDocumentsBlock = ({
  title,
  documents,
  uploadSlice,
  emptyDocumentsMessage,
  updateDocuments,
  setShowLoadingAlert,
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    setIsUpdating(updateDocuments);
  }, [updateDocuments]);

  const filterDocuments = () => {
    if (!documents) return [];
    return documents.filter((document) => document?.documentUid != null);
  };

  const filteredDocuments = filterDocuments();

  const getDocumentUuid = (document) => document?.documentUid || document?.templateUid;

  const handleUpdate = () => {
    setIsUpdating(true);
    setShowLoadingAlert(true);
    dispatch(uploadSlice({ uid: params.uid })).then(() => {
      setIsUpdating(false);
      setShowLoadingAlert(false);
    });
  };

  if (filteredDocuments.length === 0) return null;

  return (
    <div className='summary-documents-block'>
      <h3 className={`document-type-title document-type-title_with-title-button`}>
        <div>{title}</div>
        <div className='update_documents title-button'>
          <button onClick={handleUpdate} className='button'>
            <img className='button__icon-left' src={isUpdating ? SuccessCheckmarkWhite : UpdateIcon} alt='btn-icon' />
            <span className='button-label'>{isUpdating ? 'Updating...' : 'Update documents'}</span>
          </button>
        </div>
      </h3>
      {filteredDocuments.length > 0 && (
        <div className='documents-block'>
          {filteredDocuments.map((document) => {
            return <SummaryDocument key={getDocumentUuid(document)} document={document} />;
          })}
        </div>
      )}
      {filteredDocuments.length === 0 && <p>{emptyDocumentsMessage}</p>}
    </div>
  );
};

export default SummaryDocumentsBlock;
