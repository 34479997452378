export enum DocumentCategory {
  OFFICIAL_LIST_CERTIFICATES = 'Certificates',
  CARGO_DOCUMENTS = 'Cargo Documents',
  LOCAL_FORMALITIES = 'Local Formalities',
  DUE_DOCUMENTS = 'DUE Documents',
}

export enum DocumentSubCategory {
  HongKongConventionAndEUShipRecyclingRegulation = 'Hong Kong Convention and EU Ship Recycling Regulation',
  TonnageAndLoadLineConventions = 'Tonnage and Load Line Conventions',
  CertificateOfRegistryAndClassification = 'Certificate of Registry & Classification',
  SOLAS = 'Convention for the Safety of Life at Sea (SOLAS)',
  MARPOLCertificatesMEPC = 'MARPOL Certificates / MEPC',
  CLCConvention = 'CLC Convention',
  IAFSConvention = 'IAFS Convention',
  InsuranceCertificates = 'Insurance Certificates',
  SpecialTradePassenger = 'Special Trade Passenger',
  WorldHealthOrganization = 'World Health Organization',
  SafetyAndSecurityCertificates = 'Safety and Security Certificates',
  EnvironmentalCertificates = 'Environmental Certificates',
  CargoDocuments = 'Cargo Documents',
  Terminals = 'Terminals',
  Pilots = 'Pilots',
  Health = 'Health',
  Captain = 'Captain',
  Other = 'Other',
}

export const DOCUMENT_CATEGORIES = {
  PRE_ARRIVAL_DOCUMENTS: 'preArrivalDocuments',
  PRE_DEPARTURE_DOCUMENTS: 'preDepartureDocuments',
};

export const UPLOAD_CATEGORIES = {
  ARRIVAL: 'arrival',
  DEPARTURE: 'departure',
};

export const DOCUMENT_TYPE_IDS = {
  MOU: 'DUE-CAPTAIN-0001',
  CREW: 'DUE-CAPTAIN-0002',
  CREW_SIGNED: 'DUE-CAPTAIN-0003',
  WDR: 'DUE-CAPTAIN-0004',
  ISPS: 'DUE-CAPTAIN-0005',
  CREW_EFFECTS: 'DUE-CAPTAIN-0006',
  BONDED_STORES: 'DUE-CAPTAIN-0007',
  ARRIVAL_CREW: 'DUE-CAPTAIN-0009',
  DEPARTURE_CREW: 'DUE-CAPTAIN-0010',
  ARRIVAL_PASSENGER: 'DUE-CAPTAIN-0011',
  DEPARTURE_PASSENGER: 'DUE-CAPTAIN-0012',
  ARRIVAL_STORES: 'DUE-CAPTAIN-0013',
  DEPARTURE_STORES: 'DUE-CAPTAIN-0014',
  ARRIVAL_GENERAL_DECLARATION: 'DUE-CAPTAIN-0015',
  DEPARTURE_GENERAL_DECLARATION: 'DUE-CAPTAIN-0016',
  MARPOL_ADVANCED_NOTIFICATION_FORM: 'DUE-CAPTAIN-0017',
  DANGEROUS_GOODS_ARRIVAL: 'DUE-CAPTAIN-0018',
  MARITIME_DECLARATION_OF_HEALTH: 'DUE-CAPTAIN-0019',
  UPLOAD_ARRIVAL_CREW: 'UPLOAD-0001',
  UPLOAD_DEPARTURE_CREW: 'UPLOAD-0002',
  UPLOAD_HEALTH_DECLARATION: 'UPLOAD-0003',
  UPLOAD_WDR: 'UPLOAD-0004',
  UPLOAD_SANDACH: 'UPLOAD-0005',
  UPLOAD_WASTE_DELIVERY_NOTE: 'UPLOAD-0006',
  LOCAL_FORMALITY_OTHER: 'LOCAL-OTHER-0001',
};

export const CARGO_DOUMENTS = {
  CARGO_MANIFEST: { id: 'CARGO-CARGO-0001', name: 'Cargo Manifest' },
  BILL_OF_LADING: { id: 'CARGO-CARGO-0004', name: 'Bill of Lading' },
  QUALITY_CERTIFICATE_OF_CARGO: { id: 'CARGO-CARGO-0007', name: 'Quality Certificate of Cargo' },
};

export const CERTIFICATES = {
  ISSC: { id: 'CERT-SSC-0002', name: 'International Ship Security Certificate (ISSC)' },
  ISO_14001: { id: 'CERT-EC-0004', name: 'ISO 14001 - Environmental Management System' },
  BCLC: {
    id: 'CERT-CLC-0001',
    name: 'Certificate of insurance or other financial security in respect of civil liability for bunker oil pollution damage',
  },
  CLC: {
    id: 'CERT-CLC-0002',
    name: 'Certificate of insurance or other financial security in respect of civil liability for oil pollution damage',
  },
  IHM: { id: 'CERT-HKCEUSRR-0001', name: 'Inventory of Hazardous Materials (IHM)' },
};

export const DOCUMENT_TYPE_NAMES = {
  CREW_PDF_DOCUMENT: 'Uploaded PDF Crew List',
  CREW_EXCEL_DOCUMENT: 'Uploaded Excel Crew List',
};
